import { ref, computed } from 'vue'
import { useTranslate } from "@shared/composable/useTranslate";

export function useApplyLinks(props: Record<string, any>) {
  const { translate } = useTranslate();

  const LINKS_BY_ATTR = {
    "data-privacy-policy-link": props.pageData?.applicationLinks?.privacyUrl,
    "data-terms-of-use-link": props.pageData?.applicationLinks?.termsOfServiceUrl,
    "data-customer-service-link": props.pageData?.applicationLinks?.customerSupportUrl,
    "data-generic-app-link": props.pageData?.applicationLinks?.genericDownloadUrl
  }

  const translated = translate(props.value, props.locale, props.element.options?.params);
  
  const textValue = ref(
    props.isEditorMode
      ? translated
      : Object.entries(LINKS_BY_ATTR).reduce((acc, [key, value]) => {
          return acc.replace(new RegExp(key, 'g'), `${key} href="${value || '#'}" target="_blank"`)
        }, translated)
  );

  const linksAttrs = computed(() => {
    if (props.isEditorMode || props.element.tagName !== 'a') return {};

    return Object.entries(props.element.attr)
      .filter(([key]) => /^data-/.test(key))
      .reduce((acc, item) => {
        const key = item[0] as keyof typeof LINKS_BY_ATTR;
        const val = LINKS_BY_ATTR[key];
        if (val) {
          acc['href'] = val || '#';
          acc['target'] = '_blank'
        }
        return acc;
      }, {} as Record<string, any>)
  })

  return {
    textValue,
    linksAttrs,
  }
}
